<template>
  <section>
    <v-alert
        v-for="(alert,i) in alerts"
        :key="i"
        :type="alert.error?'error':'success'"
        border="left" class="rounded-0"
         dismissible
        style="position: fixed;z-index: 999;right: 2%"
        transition="scale-transition"
    >
      {{ alert.text }}
    </v-alert>
<!--    <v-alert-->


<!--        type="success"-->

<!--        border="left" class="rounded-0"-->
<!--        dismissible-->
<!--        style="position: fixed;z-index: 999;right: 2%"-->
<!--        transition="scale-transition"-->



<!--    >-->
<!--     Успешно добавена манджа-->
<!--    </v-alert>-->
  </section>
</template>

<script>
export default {
  name: "Notifications",
  data: () => ({
    alerts: []
  }),
  created() {
    this.$EventBus.$on('alert', alert => {
      this.alerts.unshift(alert);
      setTimeout(() => {
        this.alerts.splice(this.alerts.length - 1, 1);
      }, 2500);
    })
  }
}
</script>

<style scoped>

</style>